import styles from './main-screen.module.scss';
import PageTop from '../../components/page-top/page-top';
import Countries from '../../components/countries/countries';
import Footer from '../../components/footer/footer';
import VideoPlayer from '../../components/video-player/video-player';
import Container from '../../components/app/container/container';
import Faq from '../../components/faq/faq';

function MainScreen() {
  return (
    <>
      <PageTop />
      <div className={styles.bg}>
        <Container>
          <VideoPlayer video="https://heroleague.ru/video/onerun.h265.mp4" />
        </Container>
        <Countries />
        <Faq />
      </div>
      <Footer />
    </>
  );
}

export default MainScreen;
